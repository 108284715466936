import React from "react";
import { ListGroup } from "react-bootstrap";

const ApplicationBenefits = ({ benefitsList }) => {
  return (
    <ListGroup variant="flush">
      {benefitsList.map((value, index) => (
        <ListGroup.Item
          as="li"
          key={`benefitsList-${index}`}
          className={"bg-dark border-0"}
        >
          <i className="bi bi-droplet me-1"></i>{value}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default ApplicationBenefits;
