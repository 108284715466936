import Spinner from "react-bootstrap/Spinner";
import './GlobalLoader.scss'

function GlobalLoader() {
  return (
    <div className="globalLoader">
      <Spinner animation="grow" variant="dark" role="status"></Spinner>
      <Spinner animation="grow" variant="primary" role="status"></Spinner>
      <Spinner animation="grow" variant="dark" role="status"></Spinner>
      <Spinner animation="grow" variant="primary" role="status"></Spinner>
      <Spinner animation="grow" variant="dark" role="status"></Spinner>
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}

export default GlobalLoader;
