import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const Seo = ({ title, description, keywords, url, image }) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={image} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
};

Seo.defaultProps = {
  url: "/",
  image: "",
};

export default Seo;
