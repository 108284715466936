import React from "react";
import { FormattedMessage } from "react-intl";
import imgCarousel1 from "../assets/images/carousel_1.jpg";
import imgCarousel2 from "../assets/images/carousel_2.jpg";
import imgCarousel3 from "../assets/images/carousel_3.jpg";
import imgCarousel4 from "../assets/images/carousel_4.jpg";
import { Carousel, Image } from "react-bootstrap";
import { Wave } from "../components/Wave";
import "./CarouselDashboard.scss";

function CarouselDashboard() {
  return (
    <React.Fragment>
      <Carousel interval={4000} pause={false}>
        <Carousel.Item>
          <Image
            className="d-block w-100"
            fluid
            rounded
            src={imgCarousel1}
            alt=""
          />
          <Wave fill="#fff" class="wave"></Wave>
          <Carousel.Caption>
            <h1>
              <span><FormattedMessage id="components.carouselDashboard.slide1" /></span>
            </h1>
            <p>
              <FormattedMessage id="components.carouselDashboard.slide1Text" />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block w-100"
            fluid
            rounded
            src={imgCarousel2}
            alt=""
          />
          <Wave fill="#fff" class="wave"></Wave>
          <Carousel.Caption>
            <h1>
              <span><FormattedMessage id="components.carouselDashboard.slide2" /></span>
            </h1>
            <p>
              <FormattedMessage id="components.carouselDashboard.slide2Text" />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block w-100"
            fluid
            rounded
            src={imgCarousel3}
            alt=""
          />
          <Wave fill="#fff" class="wave"></Wave>
          <Carousel.Caption>
            <h1>
              <span><FormattedMessage id="components.carouselDashboard.slide3" /></span>
            </h1>
            <p>
              <FormattedMessage id="components.carouselDashboard.slide3Text" />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image
            className="d-block w-100"
            fluid
            rounded
            src={imgCarousel4}
            alt=""
          />
          <Wave fill="#fff" class="wave"></Wave>
          <Carousel.Caption>
            <h1>
              <span><FormattedMessage id="components.carouselDashboard.slide4" /></span>
            </h1>
            <p>
              <FormattedMessage id="components.carouselDashboard.slide4Text" />
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </React.Fragment>
  );
}

export default CarouselDashboard;
