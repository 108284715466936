import React from "react";
import { Chrono } from "react-chrono";
import "./Timeline.scss";


function Timeline({items}) {

  return (
    <div style={{ width: "100%" }}>
    <Chrono 
      allowDynamicUpdate={true}
      items={items}
      cardHeight={100}
      mode="VERTICAL_ALTERNATING"
      theme={{
        secondary: 'white',
        titleColor: 'white',
      }}
      fontSizes={{
        cardTitle: '1.3rem',
        cardSubtitle: '1rem',
        title: '1.4rem',
      }}
      hideControls
      
    />
    </div>
  );
}

export default Timeline;
