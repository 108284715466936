import { FormattedMessage, useIntl } from "react-intl";
import SectionContainer from "../../components/SectionContainer";
import image1 from "../../assets/images/advanced_molecular_disinfection_image1.jpg";
import image2 from "../../assets/images/advanced_molecular_disinfection_image2.jpg";
import image3 from "../../assets/images/advanced_molecular_disinfection_image3.png";
import TitleContainer from "../../components/TitleContainer";
import useIntlMessages from "../../hooks/useIntlMessages";
import ApplicationBenefits from "../../components/ApplicationBenefits";
import { Container} from "react-bootstrap";
import ResponsiveImage from "../../components/ResponsiveImage";
import SectionWrapper from "../../components/SectionWrapper";
import VimeoFrame from "../../components/VimeoFrame";
import TitleText from "../../components/TitleText";

const AdvancedMolecularDisinfection = () => {
  const intl = useIntl();
  const benefitsList = useIntlMessages(
    "applications.advancedMolecularDisinfection.benefitsList"
  );

  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="components.header.advancedMolecularDisinfection" />
      </TitleContainer>
      <SectionWrapper id="videoIntro">
        <Container>
          <VimeoFrame
            src={intl.formatMessage({
              id: "applications.advancedMolecularDisinfection.videoIntroUrl",
            })}
            title={intl.formatMessage({
              id: "components.header.advancedMolecularDisinfection",
            })}
          ></VimeoFrame>
        </Container>
      </SectionWrapper>
      <SectionContainer
        id="benefits"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.benefits.title",
            })}
            title={<ApplicationBenefits benefitsList={benefitsList} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text1"
        header={intl.formatMessage({
          id: "applications.advancedMolecularDisinfection.text1.header",
        })}
        sectionStart={<ResponsiveImage src={image1} />}
        sectionEnd={<ResponsiveImage src={image2} />}
      ></SectionContainer>
      <SectionContainer
        id="text2"
        header={intl.formatMessage({
          id: "applications.advancedMolecularDisinfection.text2.header",
        })}
        sectionStart={<ResponsiveImage src={image3} />}
      ></SectionContainer>
    </div>
  );
};

export default AdvancedMolecularDisinfection;
