import React from "react";
import { Button, Spinner } from "react-bootstrap";

const ButtonSpinner = (props) => {
  const {children, ...other} = props
  return (
    <Button {...other} disabled>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      {children}
    </Button>
  );
};

export default ButtonSpinner;
