import React from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

const ContactSocialNetworks = () => {
  const intl = useIntl();

  return (
    <Container fluid>
      <Container className="d-flex justify-content-between p-2">
        <div className="d-flex justify-content-center p-1">
          <a
            href={`mailto:${intl.formatMessage({
              id: "components.contactSocialNetworks.email",
            })}`}
            className="link-dark text-decoration-none"
          >
            <i className="bi bi-envelope me-1">
            </i>
            <FormattedMessage id="components.contactSocialNetworks.email" />
          </a>
        </div>
        <div className="d-flex justify-content-center p-1">
          <a
            href={intl.formatMessage({
              id: "components.contactSocialNetworks.whatsapp",
            })}
            rel="noreferrer"
            target="_blank"
          >
            <i className="bi bi-whatsapp text-dark mx-1"></i>
          </a>
          <a
            href={intl.formatMessage({
              id: "components.contactSocialNetworks.youtube",
            })}
            rel="noreferrer"
            target="_blank"
          >
            <i className="bi bi-youtube text-dark mx-1"></i>
          </a>
          <a
            href={intl.formatMessage({
              id: "components.contactSocialNetworks.linkedin",
            })}
            rel="noreferrer"
            target="_blank"
          >
            <i className="bi bi-linkedin text-dark mx-1"></i>
          </a>
        </div>
      </Container>
    </Container>
  );
};

export default ContactSocialNetworks;
