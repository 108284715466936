import React, { useRef, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import { Formik } from "formik";
import axios from "../api/serviceEndpoint";
import AlertMessage from "./AlertMessage";
import ToastMessage from "./ToastMessage";
import ButtonSpinner from "./ButtonSpinner";

const ContactForm = () => {
  const intl = useIntl();
  const captchaRef = useRef(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    show: false,
    message: "",
  });

  const schema = yup.object().shape({
    firstName: yup.string().required(
      intl.formatMessage({
        id: "components.contactForm.firstNameIsRequired",
      })
    ),
    lastName: yup.string().required(
      intl.formatMessage({
        id: "components.contactForm.lastNameIsRequired",
      })
    ),
    cellphone: yup
      .number()
      .typeError(
        intl.formatMessage({
          id: "components.contactForm.cellphoneIsNumber",
        })
      )
      .required(
        intl.formatMessage({
          id: "components.contactForm.cellphoneIsRequired",
        })
      ),
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: "components.contactForm.emailIsNotValid",
        })
      )
      .required(
        intl.formatMessage({
          id: "components.contactForm.emailIsRequired",
        })
      ),
  });

  const onSubmitForm = async (values, actions) => {
    actions.setSubmitting(false);
    const token = captchaRef.current.getValue();
    if (!token) {
      setError(
        intl.formatMessage({
          id: "components.contactForm.recaptchaNotFillIn",
        })
      );
      return;
    }
    let data = { ...values, token: token };
    if (values.interestAreas.length > 0) {
      const filteredInterestAreas = values.interestAreas
        .map(function (element, index) {
          return element === true ? index : -1;
        })
        .filter((element) => element !== -1);
      data = { ...data, interestAreas: filteredInterestAreas };
    }
    try {
      setLoading(true);
      const resp = await axios.post("/contacts/contactus", data);
      if (resp.status === 201) {
        setError(null);
        actions.resetForm();
        setToastMessage({
          message: intl.formatMessage({
            id: "components.contactForm.contactDataSentSuccessfully",
          }),
          show: true,
        });
      } else {
        setError(resp.data?.error);
      }
    } catch (error) {
      console.log(error.response);
      setError(error.response.data?.error);
    } finally {
      setLoading(false);
      captchaRef.current.reset();
    }
  };

  const onCloseMessageHandler = () => {
    setToastMessage({
      show: false,
      message: "",
    });
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmitForm}
      initialValues={{
        firstName: "",
        lastName: "",
        cellphone: "",
        email: "",
        comments: "",
        interestAreas: [],
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <ToastMessage
            show={toastMessage.show}
            onClose={onCloseMessageHandler}
            bg="dark"
            delay={10000}
            autohide
          >
            {toastMessage.message}
          </ToastMessage>
          <Row>
            <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="formFirstName">
                <Form.Label>
                  <FormattedMessage id="components.contactForm.firstNameLabel" />
                </Form.Label>
                <Form.Control
                  aria-label={intl.formatMessage({
                    id: "components.contactForm.firstNameLabel",
                  })}
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  className="form-control"
                  isInvalid={errors.firstName}
                  placeholder={intl.formatMessage({
                    id: "components.contactForm.firstNamePlaceholder",
                  })}
                  maxLength="50"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} xs={12} className="mb-3">
              <Form.Group controlId="formLastName">
                <Form.Label>
                  <FormattedMessage id="components.contactForm.lastNameLabel" />
                </Form.Label>
                <Form.Control
                  aria-label={intl.formatMessage({
                    id: "components.contactForm.lastNameLabel",
                  })}
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  className="form-control"
                  isInvalid={errors.lastName}
                  placeholder={intl.formatMessage({
                    id: "components.contactForm.lastNamePlaceholder",
                  })}
                  maxLength="100"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6} className="mb-3">
              <Form.Group controlId="formMobile">
                <Form.Label>
                  <FormattedMessage id="components.contactForm.cellphoneLabel" />
                </Form.Label>
                <Form.Control
                  aria-label={intl.formatMessage({
                    id: "components.contactForm.cellphoneLabel",
                  })}
                  type="tel"
                  className="form-control"
                  name="cellphone"
                  value={values.cellphone}
                  isInvalid={errors.cellphone}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({
                    id: "components.contactForm.cellphonePlaceholder",
                  })}
                  maxLength="20"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cellphone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formEmail">
                <Form.Label>
                  <FormattedMessage id="components.contactForm.emailLabel" />
                </Form.Label>
                <Form.Control
                  aria-label={intl.formatMessage({
                    id: "components.contactForm.emailLabel",
                  })}
                  type="email"
                  name="email"
                  value={values.email}
                  isInvalid={errors.email}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({
                    id: "components.contactForm.emailPlaceholder",
                  })}
                  maxLength="254"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="formInterestAreas">
              <Form.Label>
                <FormattedMessage id="components.contactForm.interestAreasLabel" />
              </Form.Label>
              <Row>
                {[
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.waterTreatment",
                    }),
                    id: 1,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.oilAndGas",
                    }),
                    id: 2,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.thermoelectricPlants",
                    }),
                    id: 3,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.coolingTowers",
                    }),
                    id: 4,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.agriculture",
                    }),
                    id: 5,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.foodPreservation",
                    }),
                    id: 6,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.aquaculture",
                    }),
                    id: 7,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.iceProduction",
                    }),
                    id: 8,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.livestock",
                    }),
                    id: 9,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.waterWells",
                    }),
                    id: 10,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.poultryAndEggWashing",
                    }),
                    id: 11,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.advancedMolecularDisinfection",
                    }),
                    id: 12,
                  },
                  {
                    name: intl.formatMessage({
                      id: "components.contactForm.interestArea.zebraMusselAndQuagga",
                    }),
                    id: 13,
                  },
                ].map((interestArea) => (
                  <Col sm={12} md={6} lg={6} key={interestArea.id}>
                    <Form.Check
                      inline
                      label={interestArea.name}
                      name="interestAreas"
                      type="checkbox"
                      checked={
                        values.interestAreas[interestArea.id]
                          ? values.interestAreas[interestArea.id]
                          : false
                      }
                      onChange={(e) =>
                        setFieldValue(
                          `interestAreas.${interestArea.id}`,
                          e.target.checked
                        )
                      }
                      id={interestArea.id}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="formComments">
              <Form.Label>
                <FormattedMessage id="components.contactForm.commentsLabel" />
              </Form.Label>
              <Form.Control
                aria-label={intl.formatMessage({
                  id: "components.contactForm.commentsLabel",
                })}
                as="textarea"
                rows={3}
                className="form-control"
                name="comments"
                value={values.comments}
                onChange={handleChange}
                placeholder={intl.formatMessage({
                  id: "components.contactForm.commentsPlaceholder",
                })}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
            />
          </Row>
          <Row className="mb-3">
            {error && <AlertMessage variant="danger">{error}</AlertMessage>}
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="formSubmit" className="text-end">
              {!loading ? (
                <Button type="submit" size="lg">
                  <FormattedMessage id="components.contactForm.submitButton" />
                </Button>
              ) : (
                <ButtonSpinner type="submit" size="lg">
                  <FormattedMessage id="components.contactForm.submitButton" />
                </ButtonSpinner>
              )}
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
