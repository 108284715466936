import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CarouselDashboard from "./CarouselDashboard";
import ContactSocialNetworks from "./ContactSocialNetworks"
import NavigationBar from "./NavigationBar";


const Header = () => {

  const location = useLocation()

  useEffect(() => {
  }, [location])

  const renderHeader = () => {

    return (location.pathname === "/" ? 
    (<header><ContactSocialNetworks/><NavigationBar/><CarouselDashboard/></header>) : 
    (<React.Fragment><header><ContactSocialNetworks/></header><NavigationBar/></React.Fragment>))
  }

  return (
    <React.Fragment>
       {renderHeader()}
    </React.Fragment>
  );
};
export default Header;
