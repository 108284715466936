import { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import TitleContainer from "../../components/TitleContainer";
import SectionWrapper from "../../components/SectionWrapper";
import HeaderApplicationBenefits from "../../components/HeaderApplicationBenefits";
import image1 from "../../assets/images/water_treatment_image1.jpg";
import image2 from "../../assets/images/water_treatment_image2.jpeg";
import image3 from "../../assets/images/water_treatment_image3.jpg";
import image4 from "../../assets/images/water_treatment_image4.jpeg";
import image5 from "../../assets/images/water_treatment_image5.jpg";
import image6 from "../../assets/images/water_treatment_image6.png";
import image7 from "../../assets/images/water_treatment_image7.png";
import image8 from "../../assets/images/water_treatment_image8.png";
import SectionContainer from "../../components/SectionContainer";
import { Container } from "react-bootstrap";
import useIntlMessages from "../../hooks/useIntlMessages";
import ResponsiveImage from "../../components/ResponsiveImage";
import VimeoFrame from "../../components/VimeoFrame";
import TitleText from "../../components/TitleText";
import ContentList from "../../components/ContentList";

const WaterTreatment = () => {
  const intl = useIntl();
  const benefitsTitle = useIntlMessages(
    "applications.waterTreatment.benefitsListTitle"
  );
  const benefitsText = useIntlMessages(
    "applications.waterTreatment.benefitsListText"
  );
  const [benefitsList, setApplicationBenefitsList] = useState([]);

  useEffect(() => {
    if (benefitsTitle && benefitsText) {
      setApplicationBenefitsList(
        benefitsTitle.map((bt, i) => ({ title: bt, text: benefitsText[i] }))
      );
    }
  }, [benefitsTitle, benefitsText]);
  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="components.header.waterTreatment" />
      </TitleContainer>
      <SectionWrapper id="videoIntro">
        <Container>
          <VimeoFrame
            src={intl.formatMessage({
              id: "applications.waterTreatment.videoIntroUrl",
            })}
            title={intl.formatMessage({
              id: "components.header.waterTreatment",
            })}
          ></VimeoFrame>
        </Container>
      </SectionWrapper>
      <SectionContainer
        id="benefits"
        sectionStart={
          <TitleText
            title={
              <h1 className="text-center">
                <FormattedMessage id="applications.benefits.title" />
              </h1>
            }
            description={
              <HeaderApplicationBenefits benefitsList={benefitsList} />
            }
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text1"
        classNames="bg-dark"
        ltr={false}
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.waterTreatment.text1.header",
            })}
            title={intl.formatMessage({
              id: "applications.waterTreatment.text1.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image1} />}
      ></SectionContainer>
      <SectionContainer
        id="text2"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.waterTreatment.text2.header",
            })}
            title={intl.formatMessage({
              id: "applications.waterTreatment.text2.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image2} />}
      ></SectionContainer>
      <SectionContainer
        id="text3"
        classNames="bg-dark"
        ltr={false}
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.waterTreatment.text3.header",
            })}
            title={intl.formatMessage({
              id: "applications.waterTreatment.text3.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image3} />}
      ></SectionContainer>
      <SectionContainer
        id="text4"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.waterTreatment.text4.header",
            })}
            title={intl.formatMessage({
              id: "applications.waterTreatment.text4.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image4} />}
      ></SectionContainer>
      <SectionContainer
        id="text5"
        classNames="bg-dark"
        ltr={false}
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.waterTreatment.text5.header",
            })}
            title={intl.formatMessage({
              id: "applications.waterTreatment.text5.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image5} />}
      ></SectionContainer>
      <SectionContainer
        id="text6"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.waterTreatment.text6.header",
            })}
            title={intl.formatMessage({
              id: "applications.waterTreatment.text6.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image6} />}
      ></SectionContainer>
      <SectionContainer
        id="text7"
        ltr={false}
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "applications.waterTreatment.text7.title",
            })}
            description={
              <ContentList
                content={intl.formatMessage({
                  id: "applications.waterTreatment.text7.description",
                })}
              ></ContentList>
            }
          />
        }
        sectionEnd={<ResponsiveImage src={image7} />}
      ></SectionContainer>
      <SectionContainer
        id="text8"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.waterTreatment.text8.header",
            })}
            title={intl.formatMessage({
              id: "applications.waterTreatment.text8.title",
            })}
            description={<ResponsiveImage src={image8} />}
          />
        }
      ></SectionContainer>
    </div>
  );
};

export default WaterTreatment;
