import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import SectionContainer from "../components/SectionContainer";
import SectionWrapper from "../components/SectionWrapper";
import nsfLogo from "../assets/images/nsf-300x300.png";
import usdaOrganicSealLogo from "../assets/images/usda-organic-seal-300x300.png";
import environmentalProtectionAgencyLogo from "../assets/images/environmental-protection-agency-300x300.png";
import MapBoxMarkers from "../components/MapBoxMarkers";
import ControlPanelGlobalPresence from "../components/ControlPanelGlobalPresence";
import globalPresence from "../assets/global_presence.json";
import Timeline from "../components/Timeline";
import TitleContainer from "../components/TitleContainer";
import TitleText from "../components/TitleText";
import VimeoFrame from "../components/VimeoFrame";
import ResponsiveImage from "../components/ResponsiveImage";
import ContentList from "../components/ContentList";

const AboutUs = () => {
  const intl = useIntl();
  const { messages } = intl;
  const [timelineItems, setTimelineItems] = useState([]);

  useEffect(() => {
    let timelineValues = [];
    const aboutusTimelineTitle = Object.entries(messages)
      .filter(([key]) => key.startsWith("aboutus.timelineTitle"))
      .map((x) => {
        return { title: x[1] };
      });
    const aboutusTimelineCardTitle = Object.entries(messages)
      .filter(([key]) => key.startsWith("aboutus.timelineCardTitle"))
      .map((x) => {
        return { cardTitle: x[1] };
      });
    const aboutusTimelineCardSubtitle = Object.entries(messages)
      .filter(([key]) => key.startsWith("aboutus.timelineCardSubtitle"))
      .map((x) => {
        return { cardSubtitle: x[1] };
      });
    if (
      aboutusTimelineTitle &&
      aboutusTimelineCardTitle &&
      aboutusTimelineCardSubtitle
    ) {
      timelineValues = aboutusTimelineTitle.map((item, i) =>
        Object.assign({}, item, aboutusTimelineCardTitle[i])
      );
      timelineValues = timelineValues.map((item, i) =>
        Object.assign({}, item, aboutusTimelineCardSubtitle[i])
      );
      setTimelineItems(timelineValues);
    }
  }, [messages]);

  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="aboutus.title" />
      </TitleContainer>
      <SectionContainer
        id="text1"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "aboutus.text1.title",
            })}
            description={intl.formatMessage({
              id: "aboutus.text1.description",
            })}
          />
        }
        sectionEnd={
          <VimeoFrame
            src={intl.formatMessage({
              id: "aboutus.videoIntroUrl",
            })}
            title={intl.formatMessage({
              id: "aboutus.title",
            })}
          ></VimeoFrame>
        }
      ></SectionContainer>
      <SectionContainer
        id="timeline"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "aboutus.timeline.header",
            })}
            title={intl.formatMessage({
              id: "aboutus.timeline.title",
            })}
            description={<Timeline items={timelineItems} />}
          />
        }
      ></SectionContainer>
      <SectionWrapper id="globalPresence">
        <Container>
          <ControlPanelGlobalPresence />
          <MapBoxMarkers
            style={{ width: "100%", height: 600 }}
            initialViewState={{
              longitude: 7.0926,
              latitude: 31.7917,
              zoom: 1,
            }}
            geojson={globalPresence}
          ></MapBoxMarkers>
        </Container>
      </SectionWrapper>
      <SectionContainer
        id="certifications"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "aboutus.certifications.header",
            })}
            title={
              <Row className="g-4">
                <Col xs={12} md={6} lg={4}>
                  <ResponsiveImage
                    src={environmentalProtectionAgencyLogo}
                    width="225"
                    height="225"
                  ></ResponsiveImage>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <ResponsiveImage
                    src={usdaOrganicSealLogo}
                    width="225"
                    height="225"
                  ></ResponsiveImage>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <ResponsiveImage src={nsfLogo} width="225" height="225" />
                </Col>
              </Row>
            }
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text3"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "aboutus.text2.title",
            })}
            description={
              <TitleText
                header={intl.formatMessage({
                  id: "aboutus.text2.description.header",
                })}
                title={
                  <ContentList
                    content={intl.formatMessage({
                      id: "aboutus.text2.description.title",
                    })}
                  ></ContentList>
                }
              />
            }
          />
        }
      ></SectionContainer>
    </div>
  );
};

export default AboutUs;
