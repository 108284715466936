import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const NotFound = () => {
  return (
    <div>
      <Container>
        <h1 className="text-center">
          <FormattedMessage id="notfound.title" />
        </h1>
        <div className="text-center">
        <Link to="/" className="btn btn-outline-dark">
          <FormattedMessage id="notfound.btnReturn" />
        </Link>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
