import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "react-bootstrap";
import "./ContentList.scss";


const ContentList = ({ content, classNames, orderedList }) => {
  const splitContentList = content.split(/\n/);

  return (
    <ListGroup as={`${orderedList ? "ol" : "ul"}`} numbered={orderedList}>
      {splitContentList.map((value, index) => (
        <ListGroup.Item
          as="li"
          key={`list-${index}`}
          className={`bg-transparent border-0 text-start ${!orderedList && "list-group-item-bullet"}${classNames} `}>
          {value}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

ContentList.propTypes = {
  content: PropTypes.string,
  classNames: PropTypes.string,
  orderedList: PropTypes.bool
};

ContentList.defaultProps = {
  classNames: "",
  orderedList: false
};
export default ContentList;
