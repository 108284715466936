import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { ScrollRestoration, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import ContactUsFloatingButton from "../components/ContactUsFloatingButton";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Seo from "../components/Seo";
import * as analytics from "../ga4";
import CookieConsent from "../components/CookieConsent";

const Home = () => {
  const location = useLocation();
  const intl = useIntl();
  const [translationPage, setTranslationPage] = useState("default");
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  const handleAcceptCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setTranslationPage("dashboard");
        break;
      case "/technology":
        setTranslationPage("technology");
        break;
      case "/product":
        setTranslationPage("product");
        break;
      case "/aboutus":
        setTranslationPage("aboutus");
        break;
      case "/resources/documents":
        setTranslationPage("resources.documents");
        break;
      case "/resources/videos":
        setTranslationPage("resources.videos");
        break;
      case "/applications/aquaculture":
        setTranslationPage("applications.aquaculture");
        break;
      case "/applications/agriculture":
        setTranslationPage("applications.agriculture");
        break;
      case "/applications/cooling-towers":
        setTranslationPage("applications.coolingTowers");
        break;
      case "/applications/oil-and-gas":
        setTranslationPage("applications.oilAndGas");
        break;
      case "/applications/ice-production":
        setTranslationPage("applications.iceProduction");
        break;
      case "/applications/thermoelectric-plants":
        setTranslationPage("applications.thermoelectricPlants");
        break;
      case "/applications/water-treatment":
        setTranslationPage("applications.waterTreatment");
        break;
      case "/applications/advanced-molecular-disinfection":
        setTranslationPage("applications.advancedMolecularDisinfection");
        break;
      case "/applications/food-preservation":
        setTranslationPage("applications.foodPreservation");
        break;
      case "/applications/livestock":
        setTranslationPage("applications.livestock");
        break;
      case "/applications/zebra-mussel-and-quagga":
        setTranslationPage("applications.zebraMusselAndQuagga");
        break;
      case "/applications/poultry-and-egg-washing":
        setTranslationPage("applications.poultryAndEggWashing");
        break;
      default:
        setTranslationPage("default");
        break;
    }
    if (cookies.cookieConsent) {
        const path = location.pathname + location.search;
        analytics.sendPageview(path);
    }
  }, [location, cookies]);

  return (
    <React.Fragment>
      {translationPage && (
        <Seo
          title={intl.formatMessage({
            id: `${translationPage}.meta.title`,
          })}
          description={intl.formatMessage({
            id: `${translationPage}.meta.description`,
          })}
          keywords={intl.formatMessage({
            id: `${translationPage}.meta.keywords`,
          })}
          url={`${
            translationPage === "default"
              ? "/"
              : process.env.PUBLIC_URL + location.pathname
          }`}
          image={intl.formatMessage({
            id: "default.meta.image",
          })}
        />
      )}
      <ScrollRestoration />
      <Header></Header>
      <main className="d-flex flex-column min-vh-100 pt-3">
        <Outlet />
        <ContactUsFloatingButton />
      </main>
      <Footer />
      {!cookies.cookieConsent && (
        <CookieConsent
          onAccept={handleAcceptCookieConsent}
          textAcceptButton={intl.formatMessage({
            id: "default.cookies.textAcceptButton",
          })}
          textConsent={intl.formatMessage({
            id: "default.cookies.textConsent",
          })}
          textLearnMore={intl.formatMessage({
            id: "default.cookies.textLearnMore",
          })}
          linkLearnMore={intl.formatMessage({
            id: "default.cookies.linkLearnMore",
          })}
        />
      )}
    </React.Fragment>
  );
};

export default Home;
