import * as React from "react";
import { FormattedMessage } from "react-intl";
import "./ControlPanelGlobalPresence.scss";

function ControlPanelGlobalPresence() {
  return (
    <div>
      <h2 className="text-center py-4">
        <FormattedMessage id="components.controlPanelGlobalPresence.title" />
      </h2>
      <h5 className="text-end">
        <FormattedMessage id="components.controlPanelGlobalPresence.presence" />{" "}
        <i className="bi bi-geo-alt-fill marker-presence"></i>
      </h5>
      <h5 className="text-end">
        <FormattedMessage id="components.controlPanelGlobalPresence.expansion" />{" "}
        <i className="bi bi-geo-alt-fill marker-expansion"></i>
      </h5>
    </div>
  );
}

export default React.memo(ControlPanelGlobalPresence);
