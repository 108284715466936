import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const CookieConsent = ({
  onAccept,
  textAcceptButton,
  textConsent,
  textLearnMore,
  linkLearnMore,
}) => {


  return (
    <Container className="fixed-bottom mb-2 mb-sm-3 ">
      <Row className="justify-content-md-center">
        <Col md="auto" className="bg-dark rounded">
          <div className="d-flex justify-content-center align-items-center gap-3 p-2 ">
            <p>
              {textConsent} <a href={linkLearnMore}>{textLearnMore}</a>
            </p>
            <Button
              onClick={() => {
                onAccept();
              }}
              size="sm"
            >
              {textAcceptButton}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
CookieConsent.propTypes = {
  onAccept: PropTypes.func,
  textAcceptButton: PropTypes.string,
  textConsent: PropTypes.string,
  textLearnMore: PropTypes.string,
  linkLearnMore: PropTypes.string,
};

export default CookieConsent;
