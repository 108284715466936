import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./CardOverlay.scss";

const CardOverlay = ({to, image, altImage, title }) => {
  return (
    <Link as={Link} to={to}>
      <Card className="bg-dark text-center rounded-circle ratio ratio-1x1">
        <Card.Img
          src={image}
          alt={altImage}
          className="rounded-circle"
          style={{ objectFit: "cover", objectPosition: "center" }}
        />
        <Card.ImgOverlay className="rounded-circle align-items-center d-flex justify-content-center">
          <Card.Title className="bg-black-opacity">{title}</Card.Title>
        </Card.ImgOverlay>
      </Card>
    </Link>
  );
};

CardOverlay.propTypes = {
  to: PropTypes.string,
  image: PropTypes.string,
  altImage: PropTypes.string,
  title: PropTypes.string
};

CardOverlay.defaultProps = {
  to: "#",
  altImage: ""
};
export default CardOverlay;
