import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { WaveFooter } from "./Wave";
import { Container } from "react-bootstrap";

const Footer = () => {
  const intl = useIntl();

  return (
    <footer
      className="mt-auto bg-dark"
    >
      <WaveFooter fill="#fff" className="waveFooter"></WaveFooter>
      <Container fluid>
        <div className="row text-center">
          <div className="col-md-4 mt-md-0 mt-3 p-3">
            <h5 className="text-uppercase">
              <FormattedMessage id="components.footer.addressTitle" />
            </h5>
            <p>
              <FormattedMessage id="components.footer.address" />
            </p>
            <Link as={Link} to="/privacy-policy" className="bg-dark fw-semibold">
              <FormattedMessage id="components.footer.privacyPolicy" />
            </Link>
          </div>

          <div className="col-md-4 mb-md-0 mb-3 p-3">
            <h5 className="text-uppercase">
              <FormattedMessage id="components.footer.mainTitle" />
            </h5>
            <div className="div">
              <div className="h1 d-flex justify-content-center">
                <a
                  href={intl.formatMessage({
                    id: "components.footer.whatsapp",
                  })}
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="bi bi-whatsapp bg-dark mx-3"></i>
                </a>
                <a
                  href={intl.formatMessage({
                    id: "components.footer.youtube",
                  })}
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="bi bi-youtube bg-dark mx-3"></i>
                </a>
                <a
                  href={intl.formatMessage({
                    id: "components.footer.linkedin",
                  })}
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="bi bi-linkedin bg-dark mx-3"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-md-0 mb-3 p-3">
            <h5 className="text-uppercase">
              <FormattedMessage id="components.footer.productionTitle" />
            </h5>
            <p>
              <FormattedMessage id="components.footer.productionText" />
            </p>
          </div>
        </div>
      </Container>
      <hr className="clearfix w-100 d-md-none pb-0" />
      <div className="footer-copyright text-center py-3">
        <FormattedMessage id="components.footer.copyright" />
      </div>
    </footer>
    
  );
};

export default Footer;
