import React from "react";

const VimeoFrame = ({ src, title }) => {
  return (
    <div className="ratio ratio-16x9">
      <iframe
        src={src}
        title={title ? title : src}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        allow="autoplay"
      ></iframe>
    </div>
  );
};

export default VimeoFrame;
