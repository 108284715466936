import React from "react";
import { useInView, animated } from "@react-spring/web";
import { Container } from "react-bootstrap";

const TitleContainer = ({ children }) => {
  const [ref, springs] = useInView(
    () => ({
      to: {
        opacity: 1,
        y: 0,
      },
      from: {
        opacity: 0,
        y: 100,
      },
      config: { duration: 1000 },
    }),
    {
      once: true,
    }
  );

  return (
    <Container className="p-0 p-lg-2 p-xl-3">
      <animated.div ref={ref} style={springs}>
        <h1 className="text-center display-3 bg-dark p-2 p-lg-3">{children}</h1>
      </animated.div>
    </Container>
  );
};

export default TitleContainer;
