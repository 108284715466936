import molecular from "../assets/images/molecular.svg";
import SectionContainer from "../components/SectionContainer";
import CardOverlay from "../components/CardOverlay";
import { FormattedMessage, useIntl } from "react-intl";
import dashboardApplication1 from "../assets/images/dashboard_application_1.png";
import dashboardApplication2 from "../assets/images/dashboard_application_2.jpg";
import dashboardApplication3 from "../assets/images/dashboard_application_3.jpg";
import dashboardApplication4 from "../assets/images/dashboard_application_4.jpg";
import dashboardApplication5 from "../assets/images/dashboard_application_5.jpg";
import dashboardApplication6 from "../assets/images/dashboard_application_6.jpg";
import dashboardApplication7 from "../assets/images/dashboard_application_7.jpg";
import dashboardApplication8 from "../assets/images/dashboard_application_8.jpg";
import TitleText from "../components/TitleText";
import ResponsiveImage from "../components/ResponsiveImage";
import { Col, Row } from "react-bootstrap";

const Dashboard = () => {
  const intl = useIntl();

  const mainApplications = [
    {
      to: "/applications/water-treatment",
      image: dashboardApplication1,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application1",
      }),
    },
    {
      to: "/applications/oil-and-gas",
      image: dashboardApplication2,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application2",
      }),
    },
    {
      to: "/applications/thermoelectric-plants",
      image: dashboardApplication3,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application3",
      }),
    },
    {
      to: "/applications/agriculture",
      image: dashboardApplication4,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application4",
      }),
    },
    {
      to: "/applications/advanced-molecular-disinfection",
      image: dashboardApplication5,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application5",
      }),
    },
    {
      to: "/applications/aquaculture",
      image: dashboardApplication6,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application6",
      }),
    },
    {
      to: "/applications/ice-production",
      image: dashboardApplication7,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application7",
      }),
    },
    {
      to: "/applications/zebra-mussel-and-quagga",
      image: dashboardApplication8,
      title: intl.formatMessage({
        id: "dashboard.mainApplications.application8",
      }),
    },
  ];
  return (
    <div>
      <SectionContainer
        id="whatIs"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "dashboard.whatIs.header",
            })}
            title={intl.formatMessage({
              id: "dashboard.whatIs.title",
            })}
            description={intl.formatMessage({
              id: "dashboard.whatIs.description",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={molecular} />}
      ></SectionContainer>
      <SectionContainer
        id="mainApplications"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "dashboard.mainApplications.header",
            })}
            title={
              <div className="text-center">
                <FormattedMessage id="dashboard.mainApplications.title" />
              </div>
            }
            description={
              <Row className="g-4">
                {mainApplications &&
                  mainApplications.map((x) => (
                    <Col md={6} lg={3} key={x.title}>
                      <CardOverlay
                        to={x.to}
                        image={x.image}
                        title={x.title}
                      ></CardOverlay>
                    </Col>
                  ))}
              </Row>
            }
          />
        }
      ></SectionContainer>
    </div>
  );
};

export default Dashboard;
