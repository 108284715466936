import React from "react";
import PropTypes from "prop-types";

const TitleText = ({ header, title, description }) => {
  return (
    <React.Fragment>
      <h2 className="text-center">{header}</h2>
      <div className="lead text-section-container my-4">{title}</div>
      <div className="lead text-section-container">{description}</div>
    </React.Fragment>
  );
};
TitleText.propTypes = {
  header: PropTypes.string,
  title: PropTypes.any,
  description: PropTypes.any,
};

export default TitleText;
