import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import translations from "./translations";

export const Context = React.createContext();

const IntlWrapper = (props) => {

  const DEFAULT_LOCALE = document.documentElement.lang;
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [messages, setMessages] = useState(translations[DEFAULT_LOCALE]);

  function selectLanguage(e) {
    const newLocale = e;
    setLocale(newLocale);
    setMessages(translations[newLocale]);
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default IntlWrapper;
