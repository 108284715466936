import React from "react";
import PropTypes from "prop-types";
import { animated, useInView } from "react-spring";
import { Container } from "react-bootstrap";

const SectionContainer = ({
  id,
  header,
  headerClassNames,
  classNames,
  sectionStart,
  sectionEnd,
  ltr,
}) => {
  const [ref, springs] = useInView(
    () => ({
      to: { opacity: 1 },
      from: { opacity: 0 },
      config: { duration: 1000 },
    }),
    {
      once: true,
    }
  );

  return (
    <section id={id} className={`py-lg-5 py-2 ${classNames}`}>
      <Container>
        <animated.div ref={ref} style={springs}>
          {header && <h2 className={`text-center mb-3 mb-lg-5 ${headerClassNames && headerClassNames}`}>{header}</h2>}
          <div className="row align-items-center justify-content-between g-3 g-lg-4">
            <div
              className={`${sectionEnd ? "col-md-12 col-lg-6" : "col-md"} ${
                ltr ? "order-lg-1" : "order-lg-2"
              }`}
            >
              {sectionStart}
            </div>
            {sectionEnd && (
              <div
                className={`col-md p-lg-0 ${
                  ltr ? "order-last order-lg-2" : "order-last order-lg-1"
                }`}
              >
                {sectionEnd}
              </div>
            )}
          </div>
        </animated.div>
      </Container>
    </section>
  );
};

SectionContainer.propTypes = {
  id: PropTypes.string,
  header: PropTypes.string,
  headerClassNames: PropTypes.string,
  classNames: PropTypes.string,
  sectionStart: PropTypes.any,
  sectionEnd: PropTypes.any,
  ltr: PropTypes.bool,
};

SectionContainer.defaultProps = {
  id: "section-container",
  classNames: "",
  ltr: true,
};

export default SectionContainer;
