import React from "react";
import PropTypes from "prop-types";
import { Toast, ToastContainer, CloseButton } from "react-bootstrap";

const ToastMessage = (props) => {

  const { onClose, position, header, children, ...rest } = props;

  const onCloseToast = () => {
    onClose();
  };

  return (
    <ToastContainer position={position} className="p-3 position-fixed">
      <Toast
        onClose={onCloseToast}
        {...rest}
      >
        {header && (
          <Toast.Header>
            <strong className="me-auto">{header}</strong>
          </Toast.Header>
        )}
        <Toast.Body className={rest.bg === "dark" ? "text-white": "text-dark"}>
          {!header && (
            <CloseButton
              aria-label="Hide"
              className="float-end"
              variant={rest.bg === "dark" ? "white": "dark"}
              onClick={() => {
                onCloseToast();
              }}
            />
          )}
          <p>{children}</p>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

ToastMessage.propTypes = {
  onClose: PropTypes.func,
  position: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node,
  ...Toast.propTypes
};

ToastMessage.defaultProps = {
  position: "top-end"
};
export default ToastMessage;
