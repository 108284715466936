import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import "./index.scss";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalLoader from "./components/GlobalLoader";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
const IntlWrapper = React.lazy(() => import("./IntlWrapper"));

root.render(
  <React.StrictMode>
    <Suspense fallback={<GlobalLoader />}>
      <IntlWrapper>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </IntlWrapper>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
