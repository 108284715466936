import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  ListGroup,
  Image,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import logoBrand from "../assets/images/logo-brand.png";
import { useLocation, Link } from "react-router-dom";
import { Context } from "../IntlWrapper";
import "./NavigationBar.scss";
import CenteredModal from "./CenteredModal";

const NavigationBar = () => {
  const intl = useIntl();
  const location = useLocation();
  const [sticky, setSticky] = useState(null);
  const [fixed, setFixed] = useState(null);
  const [navbarClasses, setNavbarClasses] = useState(null);
  const [bg, setBg] = useState("light");
  const context = useContext(Context);
  const [languageModalShow, setLanguageModalShow] = useState(false);

  const options = [
    { value: "en", label: "English" },
    { value: "es", label: "Español" },
  ];
  const selectedLanguage = options.find((x) => x.value === context.locale);

  const languageHandler = (value) => {
    if (value !== context.locale) {
      context.selectLanguage(value);
    }
    setLanguageModalShow(false);
  };
  const handleScroll = useCallback(() => {
    if (window.pageYOffset <= 250) {
      //console.log("handleScroll", window.pageYOffset);
      setNavbarValues();
    }
  }, []);

  const setNavbarValues = () => {
    if (window.pageYOffset < 40) {
      setFixed(null);
      setBg(null);
      setNavbarClasses("active");
    } else {
      setFixed("top");
      setBg("light");
      setNavbarClasses(null);
    }
  };

  useLayoutEffect(() => {
    if (location.pathname === "/") {
      setNavbarValues();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setSticky("top");
    }
  }, [location, handleScroll]);

  const onToggle = (active) => {
    if (location.pathname === "/") {
      if (active) {
        setNavbarClasses("toggle");
      } else {
        setNavbarClasses("active");
      }
    }
  };

  return (
    <React.Fragment>
      <CenteredModal
        show={languageModalShow}
        onHide={() => setLanguageModalShow(false)}
        header={<></>}
      >
        <ListGroup variant="flush">
          {options.map((x) => (
            <ListGroup.Item
              key={x.value}
              action
              onClick={(_) => languageHandler(x.value)}
            >
              {x.label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </CenteredModal>
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky={sticky}
        fixed={fixed}
        variant={bg === "dark" ? "dark" : "light"}
        className={navbarClasses}
        bg={bg}
        onToggle={onToggle}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <Image
              alt=""
              src={logoBrand}
              style={{ maxHeight: "45px", width: "150px" }}
              fluid
            />{" "}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" activeKey={window.location.pathname}>
              <Nav.Link as={Link} to="/aboutus" eventKey="1">
                <FormattedMessage id="components.header.aboutUs" />
              </Nav.Link>
              <Nav.Link as={Link} to="/technology" eventKey="2">
                <FormattedMessage id="components.header.technology" />
              </Nav.Link>
              <NavDropdown
                title={intl.formatMessage({
                  id: "components.header.applications",
                })}
                id="collasible-nav-dropdown"
                menuVariant="dark"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/applications/water-treatment"
                  eventKey="3.1"
                >
                  <FormattedMessage id="components.header.waterTreatment" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/oil-and-gas"
                  eventKey="3.2"
                >
                  <FormattedMessage id="components.header.oilAndGas" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/thermoelectric-plants"
                  eventKey="3.3"
                >
                  <FormattedMessage id="components.header.thermoelectricPlants" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/cooling-towers"
                  eventKey="3.4"
                >
                  <FormattedMessage id="components.header.coolingTowers" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/agriculture"
                  eventKey="3.5"
                >
                  <FormattedMessage id="components.header.agriculture" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/food-preservation"
                  eventKey="3.6"
                >
                  <FormattedMessage id="components.header.foodPreservation" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/aquaculture"
                  eventKey="3.7"
                >
                  <FormattedMessage id="components.header.aquaculture" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/ice-production"
                  eventKey="3.8"
                >
                  <FormattedMessage id="components.header.iceProduction" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/livestock"
                  eventKey="3.9"
                >
                  <FormattedMessage id="components.header.livestock" />
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/contact" eventKey="3.10">
                  <FormattedMessage id="components.header.waterWells" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/poultry-and-egg-washing"
                  eventKey="3.11"
                >
                  <FormattedMessage id="components.header.poultryAndEggWashing" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/advanced-molecular-disinfection"
                  eventKey="3.12"
                >
                  <FormattedMessage id="components.header.advancedMolecularDisinfection" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/applications/zebra-mussel-and-quagga"
                  eventKey="3.13"
                >
                  <FormattedMessage id="components.header.zebraMusselAndQuagga" />
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={intl.formatMessage({
                  id: "components.header.resources",
                })}
                id="collasible-nav-dropdown"
                menuVariant="dark"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/resources/documents"
                  eventKey="4.1"
                >
                  <FormattedMessage id="components.header.documents" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/resources/videos"
                  eventKey="4.2"
                >
                  <FormattedMessage id="components.header.videos" />
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/product" eventKey="5">
                <FormattedMessage id="components.header.product" />
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" eventKey="6">
                <FormattedMessage id="components.header.contact" />
              </Nav.Link>
              <Nav.Link onClick={() => setLanguageModalShow(true)}>
                <i className="bi bi-globe me-2"></i>
                {selectedLanguage && selectedLanguage.label}
              </Nav.Link>
              <Nav.Link>
                <i className="bi bi-search me-2"></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default NavigationBar;
