import { useIntl, FormattedMessage } from "react-intl";
import SectionContainer from "../../components/SectionContainer";
import image1 from "../../assets/images/aquaculture_image1.jpg";
import image2 from "../../assets/images/aquaculture_image2.png";
import TitleContainer from "../../components/TitleContainer";
import ApplicationBenefits from "../../components/ApplicationBenefits";
import useIntlMessages from "../../hooks/useIntlMessages";
import SectionWrapper from "../../components/SectionWrapper";
import VimeoFrame from "../../components/VimeoFrame";
import { Container } from "react-bootstrap";
import TitleText from "../../components/TitleText";
import ResponsiveImage from "../../components/ResponsiveImage";

const Aquaculture = () => {
  const intl = useIntl();
  const benefitsList = useIntlMessages("applications.aquaculture.benefitsList");

  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="components.header.aquaculture" />
      </TitleContainer>
      <SectionWrapper id="videoIntro">
        <Container>
          <VimeoFrame
            src={intl.formatMessage({
              id: "applications.aquaculture.videoIntroUrl",
            })}
            title={intl.formatMessage({
              id: "components.header.aquaculture",
            })}
          ></VimeoFrame>
        </Container>
      </SectionWrapper>
      <SectionContainer
        id="benefits"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.benefits.title",
            })}
            title={<ApplicationBenefits benefitsList={benefitsList} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text1"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "applications.aquaculture.text1.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image1} />}
      ></SectionContainer>
      <SectionContainer
        id="text2"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "applications.aquaculture.text2.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image2} />}
        ltr={false}
      ></SectionContainer>
      <SectionContainer
        id="text3"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "applications.aquaculture.text3.title",
            })}
          />
        }
      ></SectionContainer>
    </div>
  );
};

export default Aquaculture;
