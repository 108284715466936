import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fab, Action } from "react-tiny-fab";
import { useIntl } from "react-intl";
import "react-tiny-fab/dist/styles.css";
import "./ContactUsFloatingButton.scss";



const ContactUsFloatingButton = () => {
  const intl = useIntl();
  const [floatingButtonOpen, setFloatingButtonOpen] = useState(false);
  const navigate = useNavigate();


  const onClickContact = () => {
    setFloatingButtonOpen(false);
    navigate("/contact", { replace: true })
  };
  return (
    <Fab
      event="click"
      className={`rtf floatingButton ${floatingButtonOpen ? "open" : "closed"}`}
      style={{ bottom: 0, right: 0, zIndex: 10 }}
      icon={<i className={floatingButtonOpen ? "bi bi-plus-lg" : "bi icon-support"}></i>}
      onClick={() => setFloatingButtonOpen(!floatingButtonOpen)}
      text={intl.formatMessage({
        id: "components.contactUsFloatingButton.mainButtonText",
      })}
    >
      <Action style={{backgroundColor: "#25D366"}} text={intl.formatMessage({
        id: "components.contactUsFloatingButton.whatsappText",
      })}>
        <a
          href={intl.formatMessage({
            id: "components.contactUsFloatingButton.whatsapp",
          })}
          rel="noreferrer"
          target="_blank"
        >
          <i className="bi bi-whatsapp" style={{color: "#FFFFFF"}}></i>
        </a>
      </Action>
      <Action text={intl.formatMessage({
        id: "components.contactUsFloatingButton.contactText",
      })} onClick={onClickContact}>
        <i className="bi bi-envelope"></i>
      </Action>
    </Fab>
  );
};

export default ContactUsFloatingButton;
