import React from "react";
import PropTypes from "prop-types";

const SectionWrapper = ({ id, classNames, children }) => {
  return (
    <section id={id} className={`py-lg-5 py-2 ${classNames}`}>
      {children}
    </section>
  );
};
SectionWrapper.propTypes = {
  id: PropTypes.string,
  classNames: PropTypes.string,
  children: PropTypes.any
};

SectionWrapper.defaultProps = {
  id: "section-wrapper",
  classNames: ""
};
export default SectionWrapper;
