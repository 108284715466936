import { Container } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import ContactForm from "../components/ContactForm";
import MapBoxMarkers from "../components/MapBoxMarkers";
import markers from "../assets/markers.json";
import SectionContainer from "../components/SectionContainer";
import React from "react";
import ContentList from "../components/ContentList";

const Contact = () => {
  const intl = useIntl();

  return (
    <div>
      <SectionContainer
        id="map"
        sectionStart={
          <Container className={"bg-dark p-4 p-lg-5"}>
            <ContactForm></ContactForm>
          </Container>
        }
        sectionEnd={
          <React.Fragment>
            <h2 className="text-center">
              <FormattedMessage id="contact.addressTitle" />
            </h2>
            <p className="lead text-section-container">
              <FormattedMessage id="contact.address" />
            </p>
            <MapBoxMarkers
              style={{ width: "100%", height: 400 }}
              initialViewState={{
                longitude: intl.formatMessage({
                  id: "contact.map.longitude",
                }),
                latitude: intl.formatMessage({
                  id: "contact.map.latitude",
                }),
                zoom: intl.formatMessage({
                  id: "contact.map.zoom",
                }),
              }}
              geojson={markers}
            />
            <h2 className="text-center pt-3">
              <FormattedMessage id="contact.dropOffPoints" />
            </h2>
            <div className="lead text-section-container">
              <ContentList
                content={intl.formatMessage({
                  id: "contact.dropOffPointsAddresses",
                })}
                orderedList={true}
              ></ContentList>
            </div>
            <p className="lead text-sm-end text-center"><strong><FormattedMessage id="contact.otherLocations" /></strong></p>
          </React.Fragment>
        }
        ltr={false}
      ></SectionContainer>
    </div>
  );
};

export default Contact;
