import React from "react";
import { Image } from "react-bootstrap";

const ResponsiveImage = (props) => {
  return (
    <div className="text-center">
      <Image {...props} fluid></Image>
    </div>
  );
};

export default ResponsiveImage;
