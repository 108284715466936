import React from "react";
import { Container } from "react-bootstrap";

const HeaderApplicationBenefits = ({ benefitsList }) => {
  return (
    <React.Fragment>
      {benefitsList.map((value, index) => (
        <div key={`benefitsList-${index}`}>
          <div className="text-center bg-dark">
            <h5 className="p-2 p-lg-3">{value.title}</h5>
          </div>
          <Container>
            <div className="lead text-section-container my-4">{value.text}</div>
          </Container>
        </div>
      ))}
    </React.Fragment>
  );
};

export default HeaderApplicationBenefits;
