import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SectionContainer from "../components/SectionContainer";
import technologyLogReduction from "../assets/images/technology_log_reduction.png";
import technologyFormula from "../assets/images/technology_formula.svg";
import technologyOrpChart from "../assets/images/technology_orp_chart.png";
import technologyEvChart from "../assets/images/technology_ev_chart.png";
import technologyOxidants from "../assets/images/technology_oxidants.png";
import { Context } from "../IntlWrapper";
import TitleContainer from "../components/TitleContainer";
import TitleText from "../components/TitleText";
import ResponsiveImage from "../components/ResponsiveImage";
import ContentList from "../components/ContentList";

const Technology = () => {
  const intl = useIntl();
  const context = useContext(Context);


  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="technology.title" />
      </TitleContainer>
      <SectionContainer
        id="text1"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "technology.text1.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={technologyFormula} />}
      ></SectionContainer>
      <SectionContainer
        id="text2"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "technology.text2.title",
            })}
          />
        }
        ltr={false}
        sectionEnd={<ResponsiveImage src={technologyLogReduction} />}
      ></SectionContainer>
      <SectionContainer
        id="text3"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "technology.text3.header",
            })}
            title={intl.formatMessage({
              id: "technology.text3.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={technologyOrpChart} />}
      ></SectionContainer>
      <SectionContainer
        id="text4"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "technology.text4.title",
            })}
          />
        }
        sectionEnd={
          <ResponsiveImage
            src={require(`../assets/images/technology_potencial_chart_${context.locale}.png`)}
          />
        }
        ltr={false}
      ></SectionContainer>
      <SectionContainer
        id="text5"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "technology.text5.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={technologyEvChart} />}
      ></SectionContainer>
      <SectionContainer
        id="text6"
        sectionStart={
          <TitleText
            title={
              <ContentList
                content={intl.formatMessage({
                  id: "technology.text6.title",
                })}
              ></ContentList>
            }
          />
        }
        sectionEnd={<ResponsiveImage src={technologyOxidants} />}
        ltr={false}
      ></SectionContainer>
    </div>
  );
};

export default Technology;
