import ga4 from "react-ga4";

const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const isProduction = process.env.NODE_ENV === "production";

export const init = () =>
  ga4.initialize(MEASUREMENT_ID, {
    testMode: !isProduction,
  });

export const sendEvent = (name) => {
  if (!ga4.isInitialized) {
    init();
  }
  ga4.event("screen_view", {
    app_name: process.env.PUBLIC_URL | "myApp",
    screen_name: name,
  });
};

export const sendPageview = (path) => {
  if (!ga4.isInitialized) {
    init();
  }
  ga4.send({
    hitType: "pageview",
    page: path,
  });
};
