import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

export const useIntlMessages = (messageId) => {
  const intl = useIntl();
  const { messages } = intl;
  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    const arrayMessages = Object.entries(messages)
      .filter(([key]) => key.startsWith(messageId))
      .map((x) => {
        return x[1];
      });
    if (arrayMessages) {
      setMessageList(arrayMessages);
    }
  }, [messages, messageId]);

  return messageList;
};
export default useIntlMessages;

