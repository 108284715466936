import React, { useMemo, useState } from "react";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MapBoxMarkers = ({ initialViewState, style, geojson }) => {
  const [popup, setPopup] = useState(null);

  const markers = useMemo(
    () =>
      geojson.features.map((feature, index) => (
        <Marker
          key={`marker-${index}`}
          anchor="center"
          longitude={feature.geometry.coordinates[0]}
          latitude={feature.geometry.coordinates[1]}
          color={feature.properties.color}
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopup(feature);
          }}
        />
      )),
    [geojson]
  );

  return (
    <>
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={initialViewState}
        style={style}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        {markers}
        {popup && (
          <Popup
            anchor="top"
            longitude={Number(popup.geometry.coordinates[0])}
            latitude={Number(popup.geometry.coordinates[1])}
            onClose={() => setPopup(null)}
          >
            <div>{popup.properties.title}</div>
          </Popup>
        )}
      </Map>
    </>
  );
};

export default MapBoxMarkers;
